const INITIAL_VALUES = {
  zone: null,
  plaza: null,
  agent: null,
  chain: null,
  branch: null,
  family: null,
  piece: '',
  amount: '',
};

export default INITIAL_VALUES;
