import DeleteIcon from '@mui/icons-material/Delete';
import { GridActionsCellItem } from '@mui/x-data-grid';

import { formatCurrency, currencyComparator } from '../../../utils';

const DESCRIPTION_COLUMNS = (handleDelete) => [
  {
    field: 'zone',
    headerName: 'Zona',
    flex: 2,
    minWidth: 100,
    valueGetter: (params) => params.row.zone?.zoneName || 'N/A',
  },
  {
    field: 'plaza',
    headerName: 'Plaza',
    flex: 2,
    minWidth: 100,
    valueGetter: (params) => params.row.plaza?.plazaName || 'N/A',
  },
  {
    field: 'agent',
    headerName: 'Agente',
    flex: 2,
    minWidth: 200,
    valueGetter: (params) => params.row.agent?.agentName || 'N/A',
  },
  {
    field: 'chain',
    headerName: 'Cadena',
    flex: 2,
    minWidth: 200,
    valueGetter: (params) => params.row.chain?.chainName || 'N/A',
  },
  {
    field: 'branch',
    headerName: 'Sucursal',
    flex: 2,
    minWidth: 200,
    valueGetter: (params) => params.row.branch?.branchName || 'N/A',
  },
  {
    field: 'family',
    headerName: 'Familia',
    flex: 2,
    minWidth: 200,
    valueGetter: (params) => params.row.family.familyName,
  },
  {
    field: 'piece',
    headerName: 'Piezas',
    flex: 2,
    type: 'number',
    minWidth: 100,
    valueGetter: (params) => params.row.piece || 'N/A',
  },
  {
    field: 'amount',
    headerName: 'Cantidad',
    flex: 2,
    type: 'number',
    minWidth: 200,
    valueGetter: (params) => formatCurrency(params.row.amount),
    sortComparator: currencyComparator,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: 'Acciones',
    flex: 1,
    minWidth: 100,
    align: 'center',
    getActions: (params) => [
      <GridActionsCellItem
        icon={<DeleteIcon />}
        label="Eliminar"
        onClick={() => handleDelete(params)}
      />,
    ],
  },
];

export default DESCRIPTION_COLUMNS;
